import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/custom.sass'
import VueCarousel from 'vue-carousel'
import VueCalendly from 'vue-calendly'
import VueScrollTo from 'vue-scrollto'
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from "vee-validate"
import * as rules from "vee-validate/dist/rules"


// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;
Vue.use(VueCarousel)
Vue.use(VueCalendly)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: -200,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

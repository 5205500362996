<template>
    <div class="mt-lg-5 mt-4 row-cols-1">
        <h1>Score Your Workflow</h1>
      <h4 v-show="this.tasks.length === 0" class="text-muted">How efficient is your team? Score Your workflow and find out.</h4>
        <!------------------Desktop Task View------------------>
        <div id="desktop-entry" class="d-none d-md-block">
            <b-table-lite :responsive="true" striped id="calc_table" primary-key="index" head-variant="light"
                          :items="tasks"
                          :fields="header">
                <template v-slot:cell(remove)="row">
                    <b-button variant="danger" size="sm" @click="removeTask(row.item.index)" class="mr-2">
                        <b-icon-trash/>
                    </b-button>
                </template>
            </b-table-lite>
            <div class="d-flex justify-content-start">
                <b-button variant="primary" v-b-modal="'calculator-modal'"><b>+</b> New Task</b-button>
            </div>
        </div>
        <!------------------Result and Chart------------------>
        <div id="calculator-result" class="mt-5 d-flex flex-wrap">
            <div id="automation-number" class="col-md-6 col-12">
                <div class="h4 text-center align-middle">You are {{Math.round(100 - autoScoreRate)}}% efficient</div>
                <div class="h5 text-center align-middle">{{Math.round(autoScoreRate)}}%  of your work can be Automated.</div>
            </div>
            <div id="calculator-chart" class="col-sm-6 col-12" v-show="tasks.length">
                <calculator-chart :chart-data="chartData"/>
            </div>
        </div>

        <!------------------Mobile Task View------------------>
        <div id="mobile-entry" class="d-block d-md-none">
            <div class="d-flex justify-content-start">
                <b-button variant="primary" v-b-modal="'calculator-modal'"><b>+</b> New Task</b-button>
            </div>
            <div id="task-list" class="d-flex flex-column">
                <div class="card card-body mt-3 text-left" v-bind:key="index"
                     v-for="(task, index) in tasks">
                    <div class="m-0 h5 font-weight-bold text-capitalize">{{task.name}}</div>
                    <hr/>
                    <div class="d-flex flex-row flex-wrap">
                        <div class="mr-3">
                            <ul class="list-unstyled font-weight-bold">
                                <li>Primary Work</li>
                                <li>Secondary Work</li>
                                <li>Time Spent</li>
                                <li>Potentially Save</li>
                            </ul>
                        </div>
                        <div>
                            <ul class="no-decore list-unstyled">
                                <li>{{task.primaryCategoryName}}</li>
                                <li>{{task.secondaryCategoryName}}</li>
                                <li>{{task.hours}} Hours</li>
                                <li>{{task.autoScore}} Hours</li>
                            </ul>
                        </div>
                    </div>
                    <div class="font-weight-bold">
                        <b-button variant="danger" size="sm" @click="removeTask(index)" class="mr-2">
                            Remove
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <!------------------Modal------------------>
        <calculator-modal ref="calculatorModal" v-on:task-created="addTask($event)"/>
    </div>
</template>

<script>
    import calculator from "../plugins/calculator";
    import CalculatorModal from "../components/CalculatorModal";
    import CalculatorChart from "../components/CalculatorChart";

    export default {
        name: "Calculator",
        components: {CalculatorModal, CalculatorChart},
        data: function () {
            return {
                header: [
                    {key: 'remove', label: 'Remove'},
                    {key: 'name', label: 'Name'},
                    {key: 'primaryCategoryName', label: 'Primary Work'},
                    {key: 'secondaryCategoryName', label: 'Secondary Work'},
                    {key: 'hours', label: 'Time Spent'},
                    {key: 'autoScore', label: 'Potentially Save'}
                ],
                tasks: [],
                chartData: {
                    datasets: [{
                        data: [],
                        backgroundColor: []
                    }],
                    labels: []
                },
                autoScoreRate: 0
            }
        },
        mounted() {
            if (localStorage.tasks) {
                this.tasks = JSON.parse(localStorage.tasks);
            }
            if (this.tasks.length === 0) {
                this.$refs.calculatorModal.$bvModal.show("calculator-modal");
            }
        },
        methods: {
            removeTask: function (index) {
                this.tasks.splice(index, 1);
                localStorage.tasks = JSON.stringify(this.tasks);
            },
            addTask: function (task) {
                task.primaryCategoryName = task.primaryCategory.name;
                task.secondaryCategoryName = task.secondaryCategory.name;
                this.tasks.push(task);
                localStorage.tasks = JSON.stringify(this.tasks);
            },
            // eslint-disable-next-line no-unused-vars
            convertTaskToData: function (tasks) {
                const data = {
                    datasets: [{
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: []
                    }],
                    labels: []
                };
                let notAutomatableScore = 0;
                let counter = 0;
                tasks.forEach(task => {
                    notAutomatableScore += task.hours - task.autoScore;
                    data.datasets[0].data.push(task.autoScore);
                    const rate = task.autoScore / task.hours;
                    const red = rate * 255;
                    const green = 0;
                    const blue = counter%8 * 32;
                    const bgColor = `rgba(${red}, ${green}, ${blue}, 0.8)`;
                    const hoverBgColor = `rgba(${red}, ${green}, ${blue}, 1)`;
                    data.datasets[0].backgroundColor.push(bgColor);
                    data.datasets[0].hoverBackgroundColor.push(hoverBgColor);
                    data.labels.push(task.name);
                    counter++;
                });
                notAutomatableScore = Number(Math.round(notAutomatableScore + 'e2') + 'e-2');
                data.datasets[0].data.unshift(notAutomatableScore);
                const red = 92;
                const green = 190;
                const blue = 95;
                const bgColor = `rgb(${red}, ${green}, ${blue})`;
                const hoverBgColor = `rgba(${red-20}, ${green}, ${blue-20}, 1)`;
                data.datasets[0].backgroundColor.unshift(bgColor);
                data.datasets[0].hoverBackgroundColor.unshift(hoverBgColor);
                data.labels.unshift('High-level work');
                return data;
            }
        },
        watch: {
            tasks: function () {
                this.autoScoreRate = (calculator.automationRate(this.tasks) * 100).toFixed(2);
                this.chartData = this.convertTaskToData(this.tasks);
            }
        }
    }
</script>

<template>
    <b-modal @hide="reset" title="Add New Task" id="calculator-modal">
        <b-carousel :interval=0 no-touch v-model="pageNumber">

            <b-carousel-slide img-blank>
                <template v-slot:img>
                    <div class="col">
                        <b-alert :show="showError" variant="danger">Please enter a valid task name.</b-alert>
                        <label>Add task:
                            <b-input id="taskNameInput" @keyup="showError && validate(validator,currentInputId)" v-model="task.name" placeholder=" Task Name" required/>
                        </label>
                    </div>
                </template>
            </b-carousel-slide>

            <b-carousel-slide img-blank>
                <template v-slot:img>
                    <div class="col">
                        <b-alert :show="showError" variant="danger">Please select a category.</b-alert>
                        <label>What Type of work is this Mainly?
                            <b-select id="primaryCategoryInput" @change="showError && validate(validator,currentInputId)" v-model="task.primaryCategory" required>
                                <b-select-option :value="{name:'', value:0}" disabled> Select One</b-select-option>
                                <b-select-option v-for="(value, name) in categories" v-bind:key="name"
                                                 v-bind:value="{value, name}">{{name}}
                                </b-select-option>
                            </b-select>
                        </label>
                    </div>
                </template>
            </b-carousel-slide>

            <b-carousel-slide img-blank>
                <template v-slot:img>
                    <div class="col">
                        <b-alert :show="showError" variant="danger">Please select a category.</b-alert>
                        <label class="lead">What else does it involve?
                            <b-select id="secondaryCategoryInput" @keyup="showError && validate(validator,currentInputId)" v-model="task.secondaryCategory" required>
                                <b-select-option :value="{name:'', value:0}" disabled> Select One</b-select-option>
                                <b-select-option v-for="(value, name) in categories" v-bind:key="name"
                                                 v-bind:value="{value, name}">{{name}}
                                </b-select-option>
                            </b-select>
                        </label>
                    </div>
                </template>
            </b-carousel-slide>

            <b-carousel-slide img-blank>
                <template v-slot:img>
                    <div class="col">
                        <b-alert :show="showError" variant="danger">Please enter a number more than zero.</b-alert>
                        <label class="lead">How long do you spend on this?
                            <span class="d-flex justify-content-between">
                            <b-input id="hoursInput" v-model="task.hours" @keyup="showError && validate(validator,currentInputId)" @change="showError && validate(validator,currentInputId)" placeholder="Enter number of hours"
                                     type="number" min="0"
                                     max="24" required/> <span class="ml-2 pt-1"> Hours</span>
                            </span>

                        </label>
                    </div>
                </template>
            </b-carousel-slide>


            <b-carousel-slide img-blank>
                <template v-slot:img>
                    <div class="col">
                        <p class="lead">Do you want to add more tasks?</p>
                    </div>
                </template>
            </b-carousel-slide>
        </b-carousel>
        <template v-slot:modal-footer>
            <b-button variant="primary" @click="back">{{backButtonText}}</b-button>
            <b-button variant="secondary" @click="validate(validator,currentInputId) && next()">{{nextButtonText}}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import calculator from "../plugins/calculator";

    export default {
        name: "CalculatorModal",
        data: function () {
            return {
                showError: false,
                task: {},
                categories: [],
                pageNumber: 0,
                totalPage: 5,
                closeModal: false,
                currentInputId: null,
                validator: null,
                pageValidationList: []
            }
        },
        created: function () {
            this.task = Object.assign({}, calculator.taskObject);
            this.categories = calculator.config.categories;
            this.pageValidationList= [
                [this.isValidString, 'taskNameInput'],
                [this.isValidCategory, 'primaryCategoryInput'],
                [this.isValidCategory, 'secondaryCategoryInput'],
                [this.isValidHour, 'hoursInput'],
            ];
            if (this.pageValidationList.length > 0) {
                this.validator  = this.pageValidationList[0][0];
                this.currentInputId = this.pageValidationList[0][1];
            }
        },
        methods: {
            next: function () {
                if (this.pageNumber < this.totalPage - 1) {
                    if (this.pageNumber === this.totalPage - 2) {
                        this.task.autoScore = calculator.autoScoreFormula(calculator.config, this.task);
                        this.task.hours = parseInt(this.task.hours);
                        this.$emit('task-created', this.task)
                    }
                    this.pageNumber++;
                } else {
                    this.task = Object.assign({}, calculator.taskObject);
                    this.pageNumber = 0
                }
                if (this.pageValidationList.length > this.pageNumber) {
                    this.validator = this.pageValidationList[this.pageNumber][0];
                    this.currentInputId = this.pageValidationList[this.pageNumber][1];
                }
            },
            back: function () {
                this.showError = false;
                if (this.pageNumber > 0 && this.backButtonText === 'Back') {
                    this.pageNumber--;
                } else {
                    this.$bvModal.hide("calculator-modal")
                }
                if (this.pageValidationList.length > this.pageNumber) {
                    this.validator = this.pageValidationList[this.pageNumber][0];
                    this.currentInputId = this.pageValidationList[this.pageNumber][1];
                }
            },
            reset: function () {
                this.pageNumber = 0;
                this.showError = false;
                this.task = Object.assign({}, calculator.taskObject);
            },
            isValidHour: function (el) {
                const value = el.value;
                return value !== '' && !isNaN(value) && parseInt(value) > 0
            },
            isValidCategory: function (el) {
                return el.selectedIndex > 0
            },
            isValidString: function (el) {
                return el.value.length > 3
            },
            validate: function (validator, inputId) {
                const element = document.getElementById(inputId);
                const result = validator(element);
                this.showError = !result;
                return result
            }
        },
        computed: {
            nextButtonText: function () {
                if (this.pageNumber === this.totalPage - 1) {
                    return 'Yes'
                }
                return 'Next'
            },
            backButtonText: function () {
                if (this.pageNumber === 0) {
                    return 'Cancel'
                }
                if (this.pageNumber === this.totalPage - 1) {
                    return 'No I\'m Done'
                }
                return 'Back'
            }
        }
    }
</script>

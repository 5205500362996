<template>
    <b-modal ok-only id="calendly-modal">
        <vue-calendly url="https://calendly.com/ppcmitchell/consultation-call?primary_color=ffa812"
                      :height="600"></vue-calendly>
    </b-modal>
</template>

<script>
    export default {
        name: "Calendly",
    }
</script>


import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Calculator from '@/views/Calculator'
import SpyTool from "@/views/SpyTool";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import TermsOfService from "@/views/TermsOfService";
import ContactUs from "@/views/ContactUs";



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: Calculator
  },
  {
    path: '/spy-tool',
    name: 'SpyTool',
    component: SpyTool
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfService
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router

<template>
  <div class="mt-lg-5 mt-4 row-cols-1">
    <div class="card-deck">
      <card>
        <template v-slot:image>
          <img class="card-img-top"
               src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
               alt="Card image cap">
        </template>
        <h5 class="card-title">Get in touch</h5>
        <form>
          <div class="form-group">
            <label for="emailInput">Your email address</label>
              <input type="email" class="form-control" id="emailInput" aria-describedby="emailHelp"
                     placeholder="Enter email"/>
          </div>
        </form>
      </card>
    </div>
  </div>

</template>

<script>
export default {
  name: "ContactUs"
}
</script>

<style scoped>

</style>
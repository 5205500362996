<template>
  <div class="footer d-flex flex-row flex-wrap">
    <div class="container">
      <div class="d-flex flex-row flex-wrap justify-content-start">
        <h6 class="h4 text-left col-12"> Ready to Scale Your Agency?</h6>
        <div class="d-flex flex-lg-nowrap align-content-center flex-wrap">
          <p class="col-lg-7 col-12 m-0 text-left">Click ‘Get Help’ and book your 30-min consultation call
            to learn more.</p>
          <div class="d-block col-lg-6 col-md-10 col-sm-12">
            <div class="d-flex flex-wrap align-content-center justify-content-between">
              <b-button class="col-md-4 col-12 text-white mt-1" v-b-modal="'calendly-modal'"
                        variant="primary">Get
                Help
              </b-button>
              <router-link to="calculator" class="btn btn-secondary col-md-7 col-12 text-white mt-1"
                           variant="secondary">Score my Workflow
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center copy-right col-12 d-block">

      <div>
        <div>
          <b-link to="terms-of-service">Terms of Service</b-link>
        </div>
        <div>
          <b-link to="privacy-policy">Privacy Policy</b-link>
        </div>
      </div>
      © 2022 Copyright: PPC META
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>

export default {
    config: {
        categories: {
            'Data Entry': 0.9,
            'Data Lookup/Export': 1,
            'Campaign Building': 0.75,
            'Spreadsheeting': 0.5,
            'Data Analysis': 0.25,
            'QA': 0.5,
            'Strategizing': 0.1,
            'Writing Reports': 0.5,
            'Calculating': 0.5,
            'Campaign Edits': 0.5,
            'Meetings': 0.25,
        },
        primaryCategoryMagnitude: 0.75
    },
    taskObject: {
        name: '',
        primaryCategory: {
            name: '',
            value: 0
        },
        secondaryCategory: {
            name: '',
            value: 0
        },
        hours: 0,
        autoScore: 0
    },
    autoScoreFormula: (config, task) => {
        let result = 0;
        if (task.secondaryCategory.value > 0) {
            result = task.primaryCategory.value * task.hours * config.primaryCategoryMagnitude;
            result += task.secondaryCategory.value * task.hours * (1 - config.primaryCategoryMagnitude);
        }
        else{
            result = task.primaryCategory.value * task.hours
        }
        return parseFloat(result.toFixed(2))
    },
    automationRate: (tasks) => {
        let totalScore = 0;
        let totalHours = 0;
        tasks.forEach(task => {
            totalScore += task.autoScore;
            totalHours += task.hours;

        });
        if (totalHours > 0) {
            return parseFloat((totalScore / totalHours).toFixed(4))
        }
        return 0
    }

}

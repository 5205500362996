<template>
  <div><p><strong>Terms of Service (&quot;Terms&quot;)</strong></p>
    <p><br>Please read these Terms of Service (&quot;Terms&quot;, &quot;Terms of Service&quot;) carefully before using
      the https://ppcmeta.com and https://app.ppcmeta.com websites (the &quot;Service&quot;) operated by PPC Meta. (&quot;us&quot;,
      &quot;we&quot;, or &quot;our&quot;).<br>Your access to and use of the Service is conditioned upon your acceptance
      of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use
      the Service.<br>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any
      part of the terms then you do not have permission to access the Service.<br><br><strong>Third-Party
        Disclaimer</strong></p>
    <p><br>By creating an Account on our service you acknowledge that we are not Google, we are a third party tool.
      Google Ads is not responsible for any data loss, or damages, client attrition from using this service. We are not
      a database for any data outside ofGoogle Ads campaign information. Google Ads, PPC Meta are not responsible for
      any sensitive information provided within our software and website.<br><br><strong>Communications from Us</strong>
    </p>
    <p><br>By creating an Account on our service you agree that we can use this information to contact you regarding
      customer support issues. You also agree to subscribe to newsletters, marketing or promotional materials and other
      information we may send to keep you updated about important announcements and changes to our
      Service.<br><br><strong>Accounts</strong></p>
    <p><br>When you create an account with us, you guarantee that you are above the age of 18, and that the information
      you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information
      may result in the immediate termination of your account on the Service.<br><br>You are responsible for maintaining
      the confidentiality of your account and password, including but not limited to the restriction of access to your
      computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under
      your account and/or password, whether your password is with our Service or a third-party service. You must notify
      us immediately upon becoming aware of any breach of security or unauthorized use of your account.<br>You may not
      use as a username the name of another person or entity or that is not lawfully available for use, a name or
      trademark that is subject to any rights of another person or entity other than you, without appropriate
      authorization. You may not use as a username any name that is offensive, vulgar or obscene.<br>We reserve the
      right to refuse service, terminate accounts, remove or edit content in our sole discretion.<br><br><strong>Intellectual
        Property</strong><br><br>The Service and its original content (excluding Content provided by users), features
      and functionality are and will remain the exclusive property of PPC Meta and its licensors. The Service is
      protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks
      and trade dress may not be used in connection with any product or service without the prior written consent of PPC
      Meta.<br><br><strong>Links To Other Web Sites</strong><br><br>Our Service may contain links to third party web
      sites or services that are not owned or controlled by PPC Meta Integrated Software.<br><br>PPC Meta Integrated
      Software has no control over, and assumes no responsibility for the content, privacy policies, or practices of any
      third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their
      websites.<br><br>You acknowledge and agree that PPC Meta shall not be responsible or liable, directly or
      indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on
      any such content, goods or services available on or through any such third party web sites or services.<br><br>We
      strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services
      that you visit.<br><br><strong>Termination</strong><br><br>We may terminate or suspend your account and bar access
      to the Service immediately, without prior notice or liability, under our sole discretion, for any reason
      whatsoever and without limitation, including but not limited to a breach of the Terms.<br>If it is determined that
      a business or agency has created multiple PPC Meta accounts to service their individual clients in order to
      circumvent the free tier restrictions, PPC Meta reserves the right to terminate all accounts associated with the
      business or agency immediately and without warning.<br><br>If you wish to terminate your account, you may simply
      discontinue using the Service.<br><br>All provisions of the Terms which by their nature should survive termination
      shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity
      and limitations of liability.<br><br><strong>Indemnification</strong><br><br>You agree to defend, indemnify and
      hold harmless PPC Meta Integrated Software and its licensee and licensors, and their employees, contractors,
      agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities,
      costs or debt, and expenses (including but not limited to attorney&apos;s fees), resulting from or arising out of
      a) your use and access of the Service, by you or any person using your account and password; b) a breach of these
      Terms, or c) Content posted on the Service.<br><br><strong>Content</strong><br><br>Our Service allows you to post,
      link, store, share and otherwise make available certain information, text, graphics, videos, or other material (&quot;Content&quot;).
      You are responsible for the Content that you post on or through the Service, including its legality, reliability,
      and appropriateness.<br><br>By posting Content on or through the Service, You represent and warrant that: (i) the
      Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license
      as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate
      the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We
      reserve the right to terminate the account of anyone found to be infringing on a copyright.<br><br>You retain any
      and all of your rights to any Content you submit, post or display on or through the Service and you are
      responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any
      third party posts on or through the Service. However, by posting Content using the Service you grant us the right
      and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and
      through the Service.<br><br>PPC Meta has the right but not the obligation to monitor and edit all Content provided
      by users.<br><br>In addition, Content found on or through this Service are the property of PPC Meta Integrated
      Software or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use
      said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance
      written permission from us.<br><br><strong>Limitation Of Liability</strong><br><br>In no event shall PPC Meta, nor
      its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental,
      special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or
      other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service;
      (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and
      (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract,
      tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of
      such damage, and even if a remedy set forth herein is found to have failed of its essential
      purpose.<br><br><strong>Disclaimer</strong><br><br>Your use of the Service is at your sole risk. The Service is
      provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of
      any kind, whether express or implied, including, but not limited to, implied warranties of merchantability,
      fitness for a particular purpose, non-infringement or course of performance.<br><br>PPC Meta Integrated Software.
      its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted,
      secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service
      is free of viruses or other harmful components; or d) the results of using the Service will meet your
      requirements.<br><br><strong>Exclusions</strong><br><br>Some jurisdictions do not allow the exclusion of certain
      warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations
      above may not apply to you.<br><br><strong>Governing Law</strong><br><br>These Terms shall be governed and
      construed in accordance with the laws of the United States, without regard to its conflict of law
      provisions.<br><br>Our failure to enforce any right or provision of these Terms will not be considered a waiver of
      those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
      provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding
      our Service, and supersede and replace any prior agreements we might have had between us regarding the
      Service.<br><br><strong>Changes</strong><br><br>We reserve the right, at our sole discretion, to modify or replace
      these Terms at any time. If a revision is a material we will provide at least 30 days notice prior to any new
      terms taking effect. What constitutes a material change will be determined at our sole discretion.<br><br>By
      continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised
      terms. If you do not agree to the new terms, you are no longer authorized to use the Service.<br><br><strong>Contact
        Us</strong><br><br>If you have any questions about these Terms, please contact us at
      feedback@ppcmeta.com.<br><br><br><br><br></p></div>
</template>

<script>
export default {
  name: "TermsOfService"
}
</script>
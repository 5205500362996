<template>
    <div class="mt-lg-5 mt-4 row-cols-1 d-flex flex-wrap justify-content-center">

        <h1>Search Spy Tool</h1>
      <div class="row d-flex justify-content-center">
        <img class="col-md-3 col-8 pt-3 pb-4 align-self-start" src="../assets/img/local_search_banner.jpg" alt="banner"/>
      </div>
        <!------------------Form------------------>
        <validation-observer ref="observer" v-slot="{ passes }" class="col-lg-7 text-left">
            <b-form @submit.stop.prevent="passes(formSubmit)">

                <b-form-group label="1. Type your search term" label-for="keyword">
                    <validation-provider
                            name="Keyword"
                            :rules="{ required: true, min: 4 }"
                            v-slot="validationContext">
                        <b-form-input v-model="form.keyword" id="keyword" name="keyword"
                                      :state="getValidationState(validationContext)"
                                      placeholder="keyword to search"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>

                </b-form-group>
                <validation-provider
                        name="Location"
                        :rules="{ required: true, min: 4 }"
                        v-slot="validationContext">
                    <b-form-group label="2. Type a location" label-for="location">
                        <b-form-input v-model="form.location" id="location" name="location"
                                      :state="getValidationState(validationContext)"
                                      placeholder="Type a city with state or zip code"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <div class="d-flex">
                    <b-form-group class="d-none d-md-block col-6 p-0" label="Select your search engine">
                        <b-form-radio-group v-model="form.searchEngine">
                            <b-form-radio value="search">Google Search</b-form-radio>
                            <b-form-radio value="map">Google Map</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group class="col-6 p-0" label="3. Select a page">
                        <b-form-radio-group>
                            <b-select v-model="form.selectedPage">
                                <b-select-option value="1">Page 1</b-select-option>
                                <b-select-option value="2">Page 2</b-select-option>
                                <b-select-option value="3">Page 3</b-select-option>
                                <b-select-option value="4">Page 4</b-select-option>
                                <b-select-option value="5">Page 5</b-select-option>
                            </b-select>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <b-form-group>
                    <b-button class="btn" type="submit"> Search </b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
        <hr/>

        <!------------------Search History------------------>
        <h2>Search History</h2>
        <b-list-group class="col-lg-7 text-center align-middle p-0">
            <b-list-group-item class="d-flex justify-content-between">
                <div class="col-2 d-flex flex-column justify-content-center">
                    <span class="d-none d-md-block">Remove</span>
                    <b-icon-trash class="d-md-none m-auto" scale="1.2"/>
                </div>
                <div class="col-2 d-flex flex-column justify-content-center">Search Engine</div>
                <div class="col-4 d-flex flex-column justify-content-center">Keyword</div>
                <div class="col-4 d-flex flex-column justify-content-center">Location</div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between cursor-pointer"
                               v-for="(history, index) in searchHistory" :key="index"
                               target="_blank">
                <b-button variant="white" size="sm" @click="removeSearchHistory(index)" class="col-2">
                    <b-icon-trash variant="danger" scale="1.2"/>
                </b-button>
                <div class="col-2 d-block m-auto">
                    <b-img @click="displaySearch(index)" class="local-search-result-icon"
                           :src="history.searchEngine==='map'?googleMapIcon:googleSearchIcon"/>
                </div>
                <div @click="displaySearch(index)" class="col-4 m-auto">{{history.keyword}}</div>
                <div @click="displaySearch(index)" class="col-4 m-auto"> {{history.location}}</div>
            </b-list-group-item>
        </b-list-group>
        <!------------------Modal------------------>
        <local-search-modal ref="localSearchModal" :local-search-data="currentSearch"/>
    </div>
</template>

<script>
  import LocalSearchModal from '../components/SpyToolModal'

  export default {
    name: 'SpyTool',
    components: { LocalSearchModal },
    data: function () {
      return {
        form: {
          keyword: '',
          location: '',
          searchEngine: 'search',
          selectedPage: "1"
        },
        generatedLinks: [],
        searchHistory: [],
        currentSearch: {},
        googleSearchIcon: require('@/assets/img/Google_Search_icon.svg'),
        googleMapIcon: require('@/assets/img/Google_Maps_icon.svg'),
        historyFields: [{
          label: 'Search Engine',
          key: 'searchEngine'
        },
          {
            label: 'Keyword',
            key: 'keyword'
          },
          {
            label: 'Location',
            key: 'location'
          }
        ]
      }
    },
    methods: {
      getValidationState ({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      formSubmit: function () {
        this.currentSearch = {
          keyword: this.form.keyword,
          location: this.form.location,
          searchEngine: this.form.searchEngine,
          pages: this.generateLinks()
        }
        window.open(this.currentSearch.pages[parseInt(this.form.selectedPage)-1], '_blank');
        this.searchHistory.unshift(this.currentSearch)
        localStorage.searchHistory = JSON.stringify(this.searchHistory)
        this.$refs.localSearchModal.$bvModal.show('local-search-modal')
      },
      generateSecretLetter: function () {
        const kwLen = this.form.location.length
        if (kwLen <= 25) {
          return String.fromCharCode(65 + kwLen)
        }
        if (kwLen <= 51) {
          return String.fromCharCode(97 + kwLen)
        }
        if (kwLen <= 61) {
          return String.fromCharCode(48 + kwLen)
        }
        return `TooLong${kwLen}`
      }
      ,
      generateLinks: function () {
        const b64TrimmedLocation = btoa(this.form.location).trim().replace(/=?=$/, '')
        const secretLetter = this.generateSecretLetter()
        const pages = []
        let mainLink = `https://www.google.com/search?q=${this.form.keyword}&gl=us&hl=en&gws_rd=cr&pws=0&uule=w+CAIQICI${secretLetter}${b64TrimmedLocation}`
        if (this.form.searchEngine === 'map') {
          mainLink += '&tbm=lcl'
        }
        pages.push(mainLink)
        for (let p = 1; p < 5; p++) {
          pages.push(mainLink + `&start=${p * 10}`)
        }
        return pages
      },
      displaySearch: function (historyIndex) {
        this.currentSearch = this.searchHistory[historyIndex]
        this.$refs.localSearchModal.$bvModal.show('local-search-modal')
      },
      removeSearchHistory: function (historyIndex) {
        this.searchHistory.splice(historyIndex, 1)
        localStorage.searchHistory = JSON.stringify(this.searchHistory)
      }
    },
    created () {
      if (localStorage.searchHistory) {
        this.searchHistory = JSON.parse(localStorage.searchHistory)
      }
    }
  }
</script>

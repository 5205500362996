<template>
    <b-navbar sticky toggleable="lg" type="light" class="navbar-custom">
        <b-navbar-brand class="col-4" to="/"><img class="row col-lg-3 col-sm-12 p-0" src="../assets/logo.png"
                                                    alt="PCC META LOGO"></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-dropdown-item>
                    <b-link class="no-decore" to="/" v-scroll-to="'#solution'">
                        Solutions
                    </b-link>
                </b-dropdown-item>
                <b-dropdown-item>
                    <b-link class="no-decore" to="spy-tool">
                        Search Spy Tool
                    </b-link>
                </b-dropdown-item>
                <b-dropdown-item>
                    <b-link class="no-decore" to="/" v-scroll-to="'#steps7'">
                        7 Steps to Success
                    </b-link>
                </b-dropdown-item>
                <b-dropdown-item>
                   <b-link class="no-decore" to="calculator">
                    Score My Workflow
                   </b-link>
                </b-dropdown-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>

</style>

<template>
    <div class="mt-lg-5 mt-4 row-cols-1">
        <div>
            <h1 class="text-left">More Accounts, Less Managers</h1>
            <div class="row text-left">
                <div class="col-lg-6 col-md-8 col-sm-12">
                    <p class="lead">
                        We help agencies automate PPC workflows so that managers can spend more time being proactive
                        rather
                        than reactive.
                    </p>
                    <div class="mt-1 d-md-block d-none">
                        <p>How efficient is your team? Score Your workflow and find out.</p>
                        <div class="row col-lg-9 col-md-10 col-sm-10 d-flex justify-content-between">
                            <b-button class="col-lg-4 col-sm-5 text-white mt-1" v-b-modal="'calendly-modal'"
                                      variant="primary">Get Help
                            </b-button>
                                <router-link to="calculator" class="btn btn-secondary col-lg-7 col-sm-6 text-white mt-1" variant="secondary">Score my
                                    Workflow
                                </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-8 col-sm-12">
                    <img class="col-12" src="../assets/img/PCCMETA-Hero-SMALL.png"/>
                </div>
                <div class="col-12 mt-4 d-md-none d-sm-block">
                    <p>How efficient is your team? Score Your workflow and find out.</p>
                    <div class="row col-lg-9 col-md-10 col-sm-10 d-flex justify-content-between">
                        <b-button class="col-lg-4 col-sm-5 text-white mt-1" v-b-modal="'calendly-modal'"
                                  variant="primary">Get Help
                        </b-button>
                            <router-link to="calculator" class="btn btn-secondary col-lg-7 col-sm-6 text-white mt-1" variant="secondary">Score my Workflow
                            </router-link >
                    </div>
                </div>
            </div>
        </div>

        <!--Cardinal Sin-->
        <hr class="divider"/>
        <div id="solution" class="section">
            <div>
                <h2 class="text-center">Avoid the “Cardinal Sin of Advertising”</h2>
                <p class="lead">
                    No more tough conversations with clients about mishandling their budgets.
                </p>
            </div>
            <div class="mt-5 d-flex flex-lg-row flex-column">
                <div class="col-lg-6 order-1 text-left">
                    <div class="pt-2">
                        <h5>Over-Spend Alerts</h5>
                        <p class="text-muted"> Know which clients campaigns have reached their agreed budget and have
                            been auto-paused.</p>
                    </div>
                    <div class="pt-2">
                        <h5>Accidental-Spend Alerts </h5>
                        <p class="text-muted"> Know which of your client’s campaigns are spending when they shouldn’t
                            be.</p>
                    </div>
                    <div class="pt-2">
                        <h5>Zero-Spend Alerts</h5>
                        <p class="text-muted"> Know which clients’ campaigns are not running when they are supposed to
                            be.</p>
                    </div>
                </div>
                <div class="col-lg-6 order-lg-1 order-0 d-flex justify-content-center">
                    <b-img-lazy class="home-image" src="../assets/img/budget-animation.svg" alt="budget image"/>
                </div>
            </div>
        </div>
        <!--Intelligent Budget Pacing-->
        <hr class="divider"/>
        <div class="section">
            <div>
                <h2 class="text-center">Intelligent Budget Pacing</h2>
                <p class="lead">
                    Make sure your campaigns run till the very last day.
                </p>
            </div>
            <div class="mt-5 d-flex flex-lg-row flex-column">
                <div class="col-lg-6 order-lg-1 order-0 d-flex justify-content-center">
                    <b-img-lazy class="home-image" src="../assets/img/project-animation.svg" alt="budget image"/>
                </div>
                <div class="col-lg-6 order-1 text-left">
                    <div class="pt-2">
                        <h5>Real-Time, Top-Level </h5>
                        <p class="text-muted"> Know how fast, or slow, all campaigns are delivering their monthly
                            budgets to the hour.</p>
                    </div>
                    <div class="pt-2">
                        <h5>Automated Adjustments </h5>
                        <p class="text-muted"> Your client’s campaign daily budgets will be regularly updated based on
                            how fast or slow they are pacing.</p>
                    </div>
                    <div class="pt-2">
                        <h5>Automated Budget Shifts</h5>
                        <p class="text-muted">Proposed changes to your un-used budgets based on all your ad
                            channels performance.</p>
                    </div>
                </div>
            </div>
        </div>
        <!--Minimal Data Entry-->
        <hr class="divider"/>
        <div class="section">
            <div>
                <h2 class="text-center">Minimal Data Entry</h2>
                <p class="lead">
                    Cut auditing, reporting and other tedious tasks down to 10%.
                </p>
            </div>
            <div class="mt-5 d-flex flex-lg-row flex-column">
                <div class="col-lg-6 order-1 text-left">
                    <div class="pt-2">
                        <h5>CRM to Campaign Sync </h5>
                        <p class="text-muted">Enter client details once like offers, budgets, locations, etc. and let
                            that data flow to all your different ad platforms.</p>
                    </div>
                    <div class="pt-2">
                        <h5>Automated Budget Proposals </h5>
                        <p class="text-muted">No more writing up budget recommendations. Budget increases are calculated
                            & displayed in one place.</p>
                    </div>
                    <div class="pt-2">
                        <h5>Automated Test Reporting </h5>
                        <p class="text-muted">No more repetitive data exports. Know the impact of each campaign change
                            on a daily basis.</p>
                    </div>
                </div>
                <div class="col-lg-6 order-lg-1 order-0 d-flex justify-content-center">
                    <b-img-lazy class="mt-n4 home-image"
                                src="../assets/img/minimalDataEntery.svg"
                                alt="budget image"/>
                </div>
            </div>
        </div>
        <!--7-Steps to a META Department-->
        <hr class="divider"/>
        <div id="steps7" class="section">
            <div>
                <h2 class="text-center">7-Steps to a META Department</h2>
            </div>
            <div class="mt-1 d-flex flex-lg-row flex-column">
                <div class="mt-1 col-lg-6 order-lg-1 order-0 d-flex justify-content-center pt-md-5">
                    <b-img-lazy  class="mt-lg-0 mt-3 dart-man"
                                src="../assets/img/dart-character.png"
                                alt="budget image"/>
                </div>
                <div class="col-lg-6 order-1 text-left d-md-block d-none">
                    <div class="pt-2">
                        <h5>1. Understand Your Agency Growth Goals</h5>
                        <p class="text-muted">Learn where you want to be and what it will take to get there.</p>
                    </div>
                    <div class="pt-2">
                        <h5>2. Discover the Biggest Pain Points</h5>
                        <p class="text-muted">Overcome challenges that hinder future growth.</p>
                    </div>
                    <div class="pt-2">
                        <h5>3. Create Baseline Team Metrics</h5>
                        <p class="text-muted">Set goals to improve your team's execution.</p>
                    </div>
                    <div class="pt-2">
                        <h5>4. Work Along-side Your Team</h5>
                        <p class="text-muted">Discover new ways of doing old things.</p>
                    </div>
                    <div class="pt-2">
                        <h5>5. Plan an Automation Roadmap</h5>
                        <p class="text-muted">Prioritize which problems to solve based on impact.</p>
                    </div>
                    <div class="pt-2">
                        <h5>6. Develop the Solutions</h5>
                        <p class="text-muted">Design routine processes that will give you peace of mind.</p>
                    </div>
                    <div class="pt-2">
                        <h5>7. Test & Refine the Process</h5>
                        <p class="text-muted">Monitor the effects of each new solution and continuously improve.</p>
                    </div>
                </div>
            </div>
            <carousel class="mt-4 d-md-none" :loop="true" :autoplayTimeout="4000" :autoplay="true" :perPage="1">
                <slide>
                    <h5>1. Understand Your Agency Growth Goals</h5>
                    <p class="text-muted">Learn where you want to be and what it will take to get there.</p>
                </slide>
                <slide>
                    <h5>2. Discover the Biggest Pain Points</h5>
                    <p class="text-muted">Overcome challenges that hinder future growth.</p>
                </slide>
                <slide>
                    <h5>3. Create Baseline Team Metrics</h5>
                    <p class="text-muted">Set goals to improve your team's execution.</p>
                </slide>
                <slide>
                    <h5>4. Work Along-side Your Team</h5>
                    <p class="text-muted">Discover new ways of doing old things.</p>
                </slide>
                <slide>
                    <h5>5. Plan an Automation Roadmap</h5>
                    <p class="text-muted">Prioritize which problems to solve based on impact.</p>
                </slide>
                <slide>
                    <h5>6. Develop the Solutions</h5>
                    <p class="text-muted">Design routine processes that will give you peace of mind.</p>
                </slide>
                <slide>
                    <h5>7. Test & Refine the Process</h5>
                    <p class="text-muted">Monitor the effects of each new solution and continuously improve.</p>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
    import {Carousel, Slide} from 'vue-carousel';

    export default {
        name: 'home',
        components: {
            Carousel,
            Slide
        }
    }
</script>

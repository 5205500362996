<template>
    <b-modal ok-only id="local-search-modal" :title="'Google ' + localSearchData.searchEngine">
        <b-list-group>
            <b-list-group-item v-for="(page, index) in localSearchData.pages" :key="index" :href="page" target="_blank"><b-img class="local-search-result-icon" :src="icon"/> Page
                {{index+1}}
            </b-list-group-item>
        </b-list-group>
    </b-modal>
</template>

<script>
  export default {
    name: 'SpyToolModal',
    props: ['localSearchData'],
    data: function () {
      return {
        googleSearchIcon: require('@/assets/img/Google_Search_icon.svg'),
        googleMapIcon: require('@/assets/img/Google_Maps_icon.svg')
      }
    },
    computed:{
      icon: function () {
        if(this.localSearchData.searchEngine === 'map'){
          return this.googleMapIcon
        }
        return this.googleSearchIcon
      }
    }
  }
</script>


